<template>
  <div
    class="list-detail-container d-flex flex-column scroll-container"
    v-if="list"
  >
    <h1 class="default--text font-weight-bold pl-3 pl-sm-0 py-3 py-sm-0">
      {{ list.name }}
    </h1>

    <div
      v-if="list.wishlistItems.length + list.wishlistInactiveItems.length > 0"
      class="list rounded-md pa-2 pa-sm-4"
    >
      <v-row
        no-gutters
        class="text-body-2 default--text font-weight-bold mb-3"
        v-if="$vuetify.breakpoint.smAndUp"
      >
        <v-col cols="5" sm="4" lg="5">
          {{ $t("lists.header.product") }}
        </v-col>
        <v-col cols="3" sm="4" md="3">
          {{ $t("lists.header.unitPrice") }}
        </v-col>
        <v-col cols="4" lg="2" md="3" class="text-center">
          {{ $t("lists.header.qty") }}
        </v-col>
      </v-row>

      <v-list v-if="list && !showSectors">
        <ListItem
          v-for="item in list.wishlistItems"
          class="py-5"
          v-bind:key="item.itemId"
          :item="item"
          :isActive="true"
          @removeItem="removeFromList"
          @update="updateList"
        ></ListItem>
        <ListItem
          v-for="item in list.wishlistInactiveItems"
          class="py-5"
          v-bind:key="item.itemId"
          :item="item"
          @removeItem="removeFromList"
          :isActive="false"
        ></ListItem>
      </v-list>
    </div>
    <v-card
      light
      depresses
      elevation="0"
      v-if="list.wishlistItems.length + list.wishlistInactiveItems.length == 0"
    >
      <v-card-title class="text-body-1 px-0 py-4 font-weight-bold">{{
        $t("lists.noProducts")
      }}</v-card-title>
    </v-card>
    <v-row
      no-gutters
      class="mt-6 mb-10 px-3"
      justify="center"
      justify-md="start"
    >
      <v-btn
        depressed
        x-large
        :outlined="$vuetify.breakpoint.smAndDown"
        :to="{ name: 'Lists' }"
        color="transparent"
        :block="$vuetify.breakpoint.smAndDown"
        :class="
          $vuetify.breakpoint.smAndDown
            ? 'mb-3 rounded-lg px-12 secondary--text'
            : 'mr-10 px-0 secondary--text'
        "
        plain
      >
        <v-icon color="secondary" class="mr-3">$chevronLeft</v-icon>
        <span>{{ $t("lists.button.backToLists") }}</span>
      </v-btn>

      <v-btn
        outlined
        x-large
        color="secondary"
        class="rounded-lg px-12 mb-3 mb-md-0"
        :block="$vuetify.breakpoint.smAndDown"
        depressed
        :disabled="
          list.wishlistItems.length + list.wishlistInactiveItems.length == 0
        "
        @click="emptyList()"
      >
        {{ $t("lists.button.emptyList") }}
      </v-btn>
      <v-spacer class="d-none d-md-flex" />
      <v-btn
        x-large
        color="primary"
        class="rounded-lg secondary--text px-12"
        :block="$vuetify.breakpoint.smAndDown"
        depressed
        @click="addAllToCart"
        :disabled="
          list.wishlistItems.length + list.wishlistInactiveItems.length == 0
        "
      >
        {{ $t("lists.button.addAllToCart") }}
      </v-btn>
    </v-row>
  </div>
</template>
<style lang="scss">
.list-detail-container {
  h1 {
    font-size: 34px;
  }
  .list {
    border: 1px solid var(--v-grey-lighten1);
    background-color: $white;
  }
  .v-list-item {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      border-bottom: 1px solid $border-color;
    }
  }
}
</style>
<script>
import ListItem from "@/components/lists/ListItem.vue";

import { mapActions } from "vuex";

import deliveryReactive from "~/mixins/deliveryReactive";

import ListService from "~/service/listService";

import map from "lodash/map";
import concat from "lodash/concat";
import groupBy from "lodash/groupBy";

export default {
  name: "ListDetail",
  props: {
    listId: { type: [Number, String], required: true },
    showSectors: { type: Boolean, default: false }
  },
  data() {
    return {
      list: null,
      panel: []
    };
  },
  components: {
    ListItem
  },
  mixins: [deliveryReactive],
  computed: {
    itemGroups() {
      let groups = new Map();
      // if( this.list)
      this.list?.wishlistItems.forEach(item => {
        item.isActive = true;
      });
      this.list?.wishlistInactiveItems.forEach(item => {
        item.isActive = false;
      });
      let allWishlistProducts = [
        ...this.list?.wishlistItems,
        ...this.list?.wishlistInactiveItems
      ];
      allWishlistProducts.forEach(item => {
        let category = this.$store.getters["category/lookupCategory"](
          item.product.categoryId
        );
        if (category === null || category === undefined) {
          category = {
            categoryId: -1,
            name: "Senza categoria"
          };
        }
        if (groups.has(category.categoryId)) {
          groups.get(category.categoryId).products.push(item);
        } else {
          groups.set(category.categoryId, {
            name: category.name,
            products: [item]
          });
        }
      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.panel = Array.from(Array(groups.size).keys());
      return groups;
    }
  },
  methods: {
    ...mapActions({
      addProductsFromWishlist: "cart/addProductsFromWishlist"
    }),
    async fetchList() {
      this.list = await ListService.getList(this.listId);
    },
    setItemGroups(array) {
      let temp = groupBy(array, "product.categoryId");
      this.itemGroups = map(temp, products => {
        let category = this.$store.getters["category/lookupCategory"](
          products[0].product.categoryId
        ).name;
        return {
          category,
          products
        };
      });
      this.panel = Array.from(Array(this.itemGroups.length).keys());
    },
    async removeFromList({ itemId, product }) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeItemFromList", {
          productName: product.name,
          productShortDescr: product.shortDescr
        })
      });
      if (res) {
        const newList = await ListService.removeItemFromList(
          this.list,
          itemId,
          product.productId
        );
        this.list = newList;
        this.setItemGroups(
          concat(this.list.wishlistInactiveItems, this.list.wishlistItems)
        );
      }
    },
    async emptyList() {
      const textConfirm = `Sei sicuro di voler svuotare la lista ${this.list.name}?`;

      let res = await this.$dialog.confirm({
        text: textConfirm
      });
      if (res) {
        const res = await ListService.emptyList(this.list);

        this.list = res;
      }
    },
    async addAllToCart() {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllToList")
      });
      if (res) {
        await this.addProductsFromWishlist(this.listId);
      }
    },
    async updateList({ newQuantity, item }) {
      const res = await ListService.updateProductsInList(
        this.listId,
        item,
        newQuantity
      );
      this.list = res;
    },
    reload() {
      this.fetchList();
    }
  },
  created() {
    this.reload();
  },
  watch: {
    listId() {
      this.reload();
    }
  }
};
</script>
