<template>
  <v-list-item
    v-if="item"
    :to="{ name: 'Product', params: { slug: item.product.slug } }"
    class="list-item px-1 py-1"
    flat
  >
    <v-row no-gutters class="product px-0 align-center white flex-sm-row">
      <v-col cols="12" sm="4" lg="5" md="5" class="product-header ">
        <v-row no-gutters align="center" class="flex-sm-column flex-md-row">
          <v-col
            cols="4"
            sm="12"
            md="4"
            lg="3"
            class="text-center text-sm-left"
          >
            <img
              :style="{ height: '80px', width: '80px' }"
              :src="item.product.mediaURL"
              :alt="item.product.name"
              onerror="this.onerror=null;this.src='/no-icon.png'"
            />
          </v-col>

          <v-list-item-content
            class="col-8 col-sm-12 col-md-8 default--text pa-0"
          >
            <div class="font-weight-bold text-body-2">
              {{ item.product.name }}
            </div>
            <div class="text-uppercase text-body-2">
              {{ item.product.shortDescr }}
            </div>
          </v-list-item-content>
        </v-row>
      </v-col>
      <v-col class="product-main" cols="12" sm="8" md="5" lg="5">
        <v-row
          no-gutters
          class="product-main-selection align-center justify-space-between d-flex"
        >
          <v-col cols="4" sm="6" lg="7">
            <ProductPrice :product="item.product" :isList="true" />
          </v-col>
          <v-col
            :offset="$vuetify.breakpoint.xs ? 1 : 0"
            cols="7"
            sm="6"
            lg="5"
            class="d-flex align-self-stretch pl-sm-0"
          >
            <template v-if="isActive">
              <div style="width: 100%;">
                <div
                  style="height: 100%;"
                  class="qty-wrap rounded-sm"
                  :class="{ 'not-empty': quantity > 0 }"
                  transition="fab-transition"
                >
                  <v-btn
                    tile
                    aria-label="Diminuisci quantità"
                    depressed
                    small
                    color="white"
                    class="minus"
                    @click.stop.prevent="minus"
                    @mousedown.stop
                    v-ripple
                  >
                    <v-icon>$minus</v-icon>
                  </v-btn>
                  <div class="val-cnt">
                    <span class="val">{{ quantity }} {{ unit }}</span>
                    <span class="small ml-1">{{ quantityPerUnit }}</span>
                  </div>
                  <v-btn
                    aria-label="Diminuisci quantità"
                    depressed
                    small
                    color="white"
                    class="plus"
                    @click.stop.prevent="plus"
                    @mousedown.stop
                    v-ripple
                  >
                    <v-icon>$plus</v-icon>
                  </v-btn>
                </div>
              </div>
            </template>
            <div
              v-else
              class="text-center mx-auto text-body-1 text-sm-body-2 font-weight-bold"
            >
              {{ $t("product.notAvailable") }}
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="2"
        class="d-flex product-cta-container mt-5 mt-md-0 justify-center"
      >
        <v-btn
          icon
          large
          class="rounded-sm primary col-6 col-sm-4 col-lg-2 pa-5"
          @click.stop.prevent="addToCart(quantity)"
        >
          <v-icon>$cart</v-icon>
          <span class="d-md-none">{{ $t("products.addToCart") }}</span>
        </v-btn>
        <v-btn
          class="rounded-sm col-6 col-sm-4 col-lg-2 pa-5"
          large
          icon
          outlined
          color="default"
          v-on:click.prevent="removeItem()"
        >
          <v-icon>$delete</v-icon>
          <span class="d-md-none">{{ $t("message.removeListitem") }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-list-item>
</template>
<style lang="scss">
.list-item {
  .product {
    &-header {
      div {
        @media screen and (min-width: 414px) and (max-width: 599px) {
          width: unset;
          margin-top: 0px;
        }
      }
    }
    &-main {
      width: 100%;
    }
    &-cta-container {
      gap: 10px;
      @media screen and (max-width: 414px) {
        width: 100%;
        // margin-top: 25px;
        .v-icon {
          display: none;
        }
      }
    }
    .icon-cart {
      color: white;
    }
  }

  .product-price {
    height: auto;
    .price {
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        font-size: 20px;
      }
    }
    .cur-price {
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        font-size: 20px;
      }
      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 14px;
        margin: 0 auto;
      }
    }
    .card {
      @media #{map-get($display-breakpoints, 'xs-only')} {
        left: 70px !important;
      }
      @media #{map-get($display-breakpoints, 'sm-only')} {
        left: auto !important;
      }
    }
  }
  .qty-wrap {
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      height: 44px !important;
    }
  }
  .v-btn {
    background-color: none;
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice.vue";

import CatalogService from "~/service/catalogService";
import CartService from "~/service/cartService";
import { mapActions } from "vuex";

export default {
  name: "ListItem",
  components: { ProductPrice },
  props: ["item", "isActive"],
  // mixins: [productMixin],

  computed: {
    quantity() {
      // let item = this.item;
      if (this.item === null || this.item.product === null) return 0;
      if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
        //Handling floating point decimals issues
        var val = (this.item.weight * 100).toFixed(0);
        val = val * CatalogService.getMultiplier(this.item.product);
        val = val / 100;
        return val;
      } else {
        return this.item.quantity;
      }
    },
    quantityPerUnit() {
      let quantity = this.quantity;
      if (quantity === 0) return;
      if (
        this.item.product.productInfos.TIPOLOGIA === "Sfuso" &&
        this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
      ) {
        try {
          var singleUnit = Math.floor(
            quantity /
              parseFloat(
                this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
              )
          );
          var singleUnitUm = this.item.product.productInfos
            .WEIGHT_SELLING_SINGLE_UNIT_UM
            ? this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT_UM
            : "pz";
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      if (this.item.product.productInfos.TIPOLOGIA === "Confezione") {
        try {
          let singleUnit =
            quantity *
            parseFloat(this.item.product.productInfos.WEIGHT_SELLING);
          singleUnit = (singleUnit * 1000).toFixed(0);
          singleUnit = singleUnit / 1000;
          let singleUnitUm = this.item.product.productInfos.WEIGHT_UNIT_SELLING;
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      return null;
    },
    unit() {
      if (this.item.product.productInfos) {
        if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
          return this.item.product.productInfos.WEIGHT_UNIT_SELLING;
        } else {
          return this.item.product.productInfos.UNIT_SELLING != null
            ? this.item.product.productInfos.UNIT_SELLING
            : "pz";
        }
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapActions({
      addProduct: "cart/addProduct"
    }),
    removeItem() {
      this.$emit("removeItem", {
        itemId: this.item.itemId,
        product: this.item.product
      });
    },
    plus() {
      const newQuantity = CartService.plus(this.item.product, this.quantity);

      this.$emit("update", { newQuantity, item: this.item });
    },
    async minus() {
      const newQuantity = CartService.minus(this.item.product, this.quantity);

      let res = true;
      if (newQuantity <= 0) {
        res = await this.$dialog.confirm({
          text: global.vm.$t("message.removeItemFromList")
        });
      }
      if (res) this.$emit("update", { newQuantity, item: this.item });
    },
    addToCart() {
      this.addProduct({ product: this.item.product, quantity: this.quantity });
    },
    hasPromo(item) {
      return (
        item.product.priceStandardDisplay &&
        item.unitPrice !== item.product.priceStandardDisplay
      );
    }
  }
};
</script>
